import { loadProgressBar } from 'axios-progress-bar'
import Cookies from 'js-cookie'
import axios from 'axios'
import { message, notification } from 'antd'

import { API_DOMAIN, I18N_LANGUAGE, JWT_TOKEN } from 'app/config'
import store from '../../store'

const apiAxios = axios.create()
const authorization = localStorage.getItem(JWT_TOKEN)
const locale = localStorage.getItem(I18N_LANGUAGE)

apiAxios.defaults.baseURL = API_DOMAIN
apiAxios.defaults.headers.common.Authorization = `Bearer: ${authorization}`
apiAxios.defaults.headers.common['Accept-Language'] = locale

apiAxios.interceptors.request.use((config) => {
  const { dispatch } = store

  dispatch({ type: 'LOADING_UI' })

  return config
})

apiAxios.interceptors.request.use((config) => {
  const configRef = config
  if (config.params) {
    Object.keys(config.params).forEach((key) => {
      configRef.params[key] = JSON.stringify(config.params[key])
    })
  }
  return configRef
})

apiAxios.interceptors.response.use(({ data }) => {
  const {
    $token, $redirect, $message,
  } = data

  if ($token) {
    // Legacy Klimbr
    if (process.env.NODE_ENV !== 'local') {
      let domain = '.uptoo.dev'
      switch (process.env.DOMAIN) {
        case 'https://api.uptoo.fr':
          domain = '.uptoo.fr'
          break
        case 'https://api.uptoo.io':
          domain = '.uptoo.io'
          break
        default:
          break
      }
      Cookies.set('adminToken', $token, { domain })
    }

    localStorage.setItem(JWT_TOKEN, $token)
    apiAxios.defaults.headers.common.Authorization = `Bearer: ${$token}`
  }

  if ($redirect) {
    const { dispatch } = store

    dispatch({
      type: 'SET_REDIRECT',
      payload: $redirect,
    })
  }

  if ($message) {
    message.success($message)
  }

  return data
}, (error) => {
  if (error.response?.data?.$message) {
    notification.error({
      message: 'Une erreur est survenue',
      description: error.response?.data?.$message,
    })

    if (error.response?.data?.$message === 'Vous n\'êtes pas identifié') {
      localStorage.removeItem(JWT_TOKEN)
      const { dispatch } = store
      dispatch({
        type: 'SET_UNAUTHENTICATED',
        payload: '/agora',
      })
    }
  }
  return Promise.reject(error)
})

loadProgressBar(null, apiAxios)

export default apiAxios
