const API_DOMAIN = process.env.DOMAIN || 'https://api.uptoo.dev'
const KLIMBR_DOMAIN = process.env.KLIMBR_DOMAIN || 'https://klimbr.uptoo.dev'
const JWT_TOKEN = 'adminToken'
const I18N_LANGUAGE = 'i18nextLng'
const THEME_TOKEN = 'theme'

export {
  API_DOMAIN,
  KLIMBR_DOMAIN,
  JWT_TOKEN,
  I18N_LANGUAGE,
  THEME_TOKEN,
}
